import classnames from 'classnames'
import React from 'react'
const SpinLoading: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classnames(
        'flex flex-col justify-center items-center w-full h-full',
        className,
      )}
    >
      <div className="relative w-16 h-16 mx-auto">
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_0s_infinite_both] rotate-[30deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-1.1s_infinite_both] rotate-[60deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-1s_infinite_both] rotate-[90deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.9s_infinite_both] rotate-[120deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.8s_infinite_both] rotate-[150deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.7s_infinite_both] rotate-[180deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.6s_infinite_both] rotate-[210deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.5s_infinite_both] rotate-[240deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.4s_infinite_both] rotate-[270deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.3s_infinite_both] rotate-[300deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.2s_infinite_both] rotate-[330deg]" />
        <div className="h-full w-full absolute left-0 top-0 before:block before:mx-auto before:w-[15%] before:h-[15%] before:bg-[#5578ba] before:rounded-[50%] before:animate-[circleFadeDelay_1.2s_ease-in-out_-0.1s_infinite_both] rotate-[360deg]" />
      </div>
      <div className="text-lg text-[#5578ba] mt-1">Loading</div>
    </div>
  )
}

export default SpinLoading
