import { useState } from 'react'
import classnames from 'classnames'

type PaginationPropsType = {
  classname?: string
  onChange?: (page: number) => void
}

/**
 * @param classname
 * @callback onChange: Called when the page number is changed, and it takes the resulting page number as its arguments
 * @returns
 */
const Pagination: React.FC<PaginationPropsType> = ({ classname, onChange }) => {
  const allPage = new Array(5).fill('')
  let [pageNum, setPageSize] = useState(0)

  const selectPage = (page: number) => {
    onChange?.(page)
    setPageSize(page)
  }

  const prevPage = () => {
    let page = --pageNum < 0 ? allPage.length - 1 : pageNum
    onChange?.(page)
    return setPageSize(page)
  }

  const nextPage = () => {
    let page = ++pageNum > allPage.length - 1 ? 0 : pageNum
    onChange?.(page)
    return setPageSize(page)
  }

  return (
    <ul
      className={classnames(
        'flex items-center justify-center mx-auto select-none max-w-7xl',
        classname,
      )}
    >
      <li
        className="w-9 h-9 rounded-[4px] mr-3 flex items-center justify-center cursor-pointer bg-[#F2F2F2] text-[#828282] hover:bg-[#1E94FC] hover:text-[#fff]"
        onClick={() => prevPage()}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </li>
      {allPage.map((x, i) => {
        return (
          <li
            key={i}
            className={`w-9 h-9  rounded-[4px] mr-3 flex items-center justify-center text-[14px] font-numberText cursor-pointer hover:bg-[#1E94FC] hover:text-[#fff] last:m-0 ${
              pageNum === i
                ? 'bg-[#1E94FC] text-[#fff]'
                : 'bg-[#F2F2F2] text-[#828282]'
            }`}
            onClick={() => selectPage(i)}
          >
            {i + 1}
          </li>
        )
      })}
      <li
        className="w-9 h-9 rounded-[4px] flex items-center justify-center cursor-pointer bg-[#F2F2F2] text-[#828282] hover:bg-[#1E94FC] hover:text-[#fff]"
        onClick={() => nextPage()}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </li>
    </ul>
  )
}

export default Pagination
