import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import SpinLoading from '../components/SpinLoading'

import img1 from '../images/learn/img1.png'
import img2 from '../images/learn/img2.png'
import { mock } from 'mockjs'
const Learn: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [list] = useState(
    new Array(6).fill({
      title:
        'Capture the Latest Opportunity: How ViaBTC Capital Picks the Promising GameFi',
      desc: 'Futures, which have no expiry date, are in a class by themselves in the crypto exchange. Since the delivery contract will be delivered on a regular basis, despite a large spread between the contract price and the spot price, the price',
      time: 'Feb 24 ·17:23:04',
      tag: 'Academy',
    }),
  )
  const [listLoading, setListLoading] = useState(true)
  const onTabChange = async (i: number) => {
    setActiveTab(i)
    setListLoading(true)
    await new Promise(resolve => setTimeout(resolve, 1200))
    setListLoading(false)
  }
  const onPageChange = async (page: number) => {
    if (typeof window !== 'undefined') {
      window?.scroll({ top: 320, behavior: 'smooth' })
    }
    setListLoading(true)
    const curPage = await new Promise(resolve =>
      setTimeout(resolve, 1200, page),
    )
    setListLoading(false)
    console.log('curPage: ', curPage)
  }
  useEffect(() => {
    new Promise(resolve => setTimeout(resolve, 1200)).then(() =>
      setListLoading(false),
    )
  }, [])

  return (
    <Layout>
      <section>
        <div className="pt-20 bg-[url('../images/learn/banner.png')] sm:bg-[url('../images/learn/mobileBanner.png')] bg-no-repeat h-[448px] bg-cover sm:pt-[57px] font-regularText sm:h-[312px]">
          <div className="flex flex-col justify-center mx-auto max-w-7xl pl-[195px] md:px-16 sm:px-4 sm:justify-start mt-[114px] sm:mt-[55px]">
            <div className="text-[34px] leading-[52px] font-boldText font-bold sm:text-[26px] sm:leading-[34px] sm:mt-0">
              CTIO Blog
            </div>
            <div className="mt-3 text-sm sm:w-[220px] sm:text-xs sm:m-0">
              Follow the real-time updates and trends of the world's leading
              digital coin exchange
            </div>
          </div>
        </div>
        <div className="bg-[rgba(255,255,255,0.44)] -mt-[50px]">
          <div className="h-full mx-auto max-w-7xl md:px-16 sm:px-4">
            <ul className="h-[50px] flex justify-center items-center text-sm sm:whitespace-nowrap sm:overflow-y-auto sm:scrollbar-no-style sm:justify-start">
              {[
                'View all',
                'Academy',
                'Research',
                'Product & Trading',
                'Product & Trading',
                'User manual',
              ].map((x, i) => (
                <li
                  key={i}
                  className={`w-[165px] sm:w-auto h-full shrink-0 flex items-center justify-center cursor-pointer sm:cursor-default relative before:absolute hover:before:border-b-2 before:border-[#1E94FC] before:w-0 hover:before:w-[56px] before:h-full before:transition-[width] before:duration-300 after:absolute after:bottom-0 after:w-14 sm:after:w-[29px] after:h-[2px] sm:mr-10 sm:before:hidden ${
                    activeTab !== i
                      ? 'after:bg-transparent'
                      : 'after:bg-[#1E94FC] sm:font-bold'
                  }`}
                  onClick={() => onTabChange(i)}
                >
                  {x}
                </li>
              ))}
            </ul>
            {listLoading ? (
              <SpinLoading className="mt-16" />
            ) : (
              <div className="mt-[112px] h-full sm:mt-[30px]">
                {list.map((x, i) => (
                  <div
                    key={i}
                    className="flex mt-12 sm:border-b first:m-0 group sm:flex-col border-[#F2F2F2] sm:pb-[30px] sm:mt-[30px]"
                    onClick={() => navigate('/learnDetails')}
                  >
                    <div className="flex w-[389px] h-[264px] rounded-2xl overflow-hidden sm:w-full sm:h-[220px]">
                      <img
                        src={[img1, img2][mock('@integer(0,1)')]}
                        alt="img1"
                        className="object-cover w-full transition-all duration-300 group-hover:scale-110"
                      />
                    </div>
                    <div className="ml-[42px] flex flex-1 flex-col justify-center cursor-pointer sm:cursor-default sm:mt-[14px] sm:ml-0">
                      <div className="text-[26px] leading-[34px] font-boldText font-bold sm:text-[18px] sm:leading-[30px] sm:line-clamp-2">
                        {x.title}
                      </div>
                      <div className="leading-[28px] text-[#828282] mt-4 sm:mt-[14px]">
                        {x.desc}
                      </div>
                      <div className="flex items-center mt-[30px] sm:mt-[14px]">
                        <div className="text-[#1E94FC] text-[14px] leading-9 sm:text-[12px]">
                          {x.time}
                        </div>
                        <div className="bg-[#F4F4F4] text-[#828282] text-[12px] h-[26px] w-[68px] text-center py-[3px] px-[6px] rounded ml-4">
                          {x.tag}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <Pagination onChange={onPageChange} classname="mt-16 sm:mt-10" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Learn
